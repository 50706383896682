import moment from "moment";

/**
 * Returns the category for a financial record.
 */
export const getCategoryForFinancialRecord = (financialRecord) => {
  return financialRecord?.assetManagementAccount?.assetManagementCategory;
};

/**
 * Returns the total actual cost for a list of financial records.
 */
export const getTotalActualsForFinancialRecords = (financialRecords) => {
  return financialRecords.reduce((acc, financialRecord) => {
    return acc + financialRecord.actualCostWithVat;
  }, 0);
};

/**
 * Returns the total actual cost for a list of financial records, grouped by month from startDate until endDate.
 */
export const getMonthlyActualsForFinancialRecords = (financialRecords, startDate, endDate) => {
  const months = getMonthsBetweenDates(startDate, endDate);

  return months.map((month) => {
    const financialRecordsForMonth = financialRecords.filter((financialRecord) => {
      return financialRecord != null && moment(financialRecord.entryDate).isSame(month, "month") && moment(financialRecord.entryDate).isSame(month, "year");
    });

    return {
      date: month,
      actuals: getTotalActualsForFinancialRecords(financialRecordsForMonth),
    };
  });
};

/**
 * Returns the total actual cost per area for a list of financial records, grouped by month from startDate until endDate.
 */
export const getMothlyActualsPrAreaForFinancialRecords = (financialRecords, startDate, endDate, area) => {
  const monthActuals = getMonthlyActualsForFinancialRecords(financialRecords, startDate, endDate);

  return monthActuals.map((monthActual) => {
    return {
      date: monthActual.date,
      actuals: monthActual.actuals / area,
    };
  });
};

/**
 * Returns a list of dates each a month apart between startDate and endDate.
 */
const getMonthsBetweenDates = (startDate, endDate) => {
  const months = [];
  let start = startDate.startOf("month");
  let end = endDate.startOf("month");

  while (start.isBefore(end)) {
    months.push(start);
    start = moment(start).add(1, "month");
  }

  return months;
};
